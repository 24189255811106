import React from "react";
import { useNavigate } from 'react-router-dom';
import StepHeader from "./StepHeader";

const Step4= () => {
  const navigate = useNavigate();

  
  const handleNavigateToDetails = () => {
    navigate('/Step5');
    };
    const handleNavigateback = () => {
      navigate('/Step3');
      };
    
    const handleCancel = () => {
        navigate('/Home');
      };
  return (
    <>

    <div className=" flex justify-center items-center h-screen">
    <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2 m-10  relative ">

    <StepHeader/>



      <div className="w-full rounded-11xl bg-powderblue overflow-hidden flex flex-row items-start justify-start gap-[90px] text-center text-5xl text-brand-white">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">1</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
          <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">2</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">3</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl [background:linear-gradient(-45deg,_#1298aa,_#29d7b7)] h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">

              <div className="relative font-medium">4</div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">5</div>
            </div>
          </div>
        </div>
        <h1 className="text-5xl font-bold mt-2">  Step 3: Configure the API</h1>

        <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-start py-7 px-[53px] gap-[10px] text-5xl">

        
        <div className="self-stretch h-[427px] flex flex-col items-start justify-start py-5 px-0 box-border gap-[12px] text-lg">
          <div className="self-stretch relative [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
          Using our white label APl it is possible to easily automate your eSIM solution.
          To get started with our API, click here
          Bundle Inventory
                View eSIMs

          </div>
          <div className="self-stretch h-[298px] flex flex-row items-start justify-start gap-[10px] text-base">
            <div className="flex-1 rounded-xl bg-white flex flex-col items-start justify-start p-5 gap-[12px]">
              <div className="self-stretch relative text-xl font-medium">
                Account Information
              </div>
              <div className="self-stretch flex flex-row items-start justify-start text-brand-dark-gray">
                <div className="flex flex-col items-start justify-start gap-[4px]">
                  <div className="relative font-medium">API Token</div>
                  <div className="w-80 relative h-[23.2px]">
                    <div className="absolute top-[0px] left-[0px] w-80 h-[23.2px] overflow-hidden flex flex-row items-start justify-start py-[8.3px] px-0 box-border gap-[8.3px]">
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                      <div className="w-[6.6px] relative rounded-[50%] bg-brand-black h-[6.6px]" />
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="self-stretch flex flex-row items-center justify-start text-brand-dark-gray">
                <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                  {/* <div className="relative font-medium">Callback URL</div> */}
                  <div className="self-stretch flex flex-row items-center justify-start gap-[20px] text-5xl">
                    <div className="flex-1 rounded-xl bg-brand-gray h-[60px] flex flex-row items-center justify-start py-2 px-5 box-border">
                      <div className="relative font-small">
                      Callback URL
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end text-right text-base text-brand-green-selected">
                      <div className="flex flex-row items-center justify-end">
                        <div className="relative font-medium">Update</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 relative text-sm [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
              It is also possible to use the API through our API pages. Why not start by sending your eSIM an SMS.
             
              </div>
            </div>
            {/* <div style={{ backgroundColor: "white" }} className="rounded-xl overflow-hidden flex flex-col items-start justify-start p-5 gap-[20px] text-center text-xl">
              <div className="self-stretch relative font-medium">
                Useful Links
              </div>
              <div className="flex flex-col items-start justify-start gap-[12px] text-base text-brand-green-selected">
                <div className="self-stretch flex flex-col items-center justify-start">
                  <div className="relative font-medium">companywebsite.com</div>
                  <div className="relative text-sm text-brand-black text-left">
                    Company
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-center justify-start">
                  <div className="relative font-medium">companywebsite.com</div>
                  <div className="relative text-sm text-brand-black text-left">
                    Company
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-center justify-start">
                  <div className="relative font-medium">companywebsite.com</div>
                  <div className="relative text-sm text-brand-black text-left">
                    Company
                  </div>
                </div>
              </div>
            </div> */}






          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 w-full bg-daefef p-8 flex justify-between">
  <button
    className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
    onClick={handleCancel}
  >
    Skip
  </button>
  <div className="flex space-x-4">
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateback}
    >
      Back
    </button>
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateToDetails}
    >
      Next
    </button>
  </div>
</div>
      
      </div>
    </div>
</>
  );
};

export default Step4;
