import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current route is one of the excluded pages
  const excludePages = ['/', '/Step1', '/Step2', '/Step3', '/Step4', '/Step5', '/CreateAccount', '/PaymentMethod', '/Wallet', '/Plan', '/Signup', '/Esimdata','/Forgotpassword',];

  const isExcludedPage = excludePages.includes(location.pathname);

  return (
    <>
      {!isExcludedPage && (
        <div className='flex flex-auto h-full'>
          <Sidebar />
          <div style={{backgroundColor:'#daefef'}} className='grow'>
            <Navbar />
            <div className='m-5'>{children}</div>
          </div>
        </div>
      )}
      {/* Render children directly for excluded pages */}
      {isExcludedPage && <div className="h-full w-full">{children}</div>}
    </>
  );
};

export default Layout;