import React from 'react';
import { TiMessages } from "react-icons/ti";
import { FaYoutube } from "react-icons/fa";

const HelpSupport = () => {
  return (
    <div className="p-4">
      <h1 className="text-5xl font-bold mb-4 text-[#197979]">Help & Support</h1>
      <div className="flex flex-col items-center justify-center bg-white p-10 rounded-2xl shadow-md">
        <h2 className="text-5xl font-bold mb-4 text-[#1d5c5c]">Let's Talk! Email or Chat with Us</h2>
        <div className='flex justify-center'>
          <a href="mailto:sales@airhubapp.com" className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mb-8 mr-4">
            Email Us <TiMessages size={30} className='ml-4' />
          </a>
          {/* Uncomment if you need chat functionality */}
          {/* <button className="text-[#fffafa] bg-[#0B4445] flex items-center border border-black px-4 py-2 rounded mb-8 mr-4">
            Chat with us <TiMessages size={30} className='ml-4'/>
          </button> */}
        </div>
        <div className='flex flex-col items-center p-4 rounded-2xl shadow-lg'>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/8lD_n_o9diU"
            title="How to Setup eSIM Activation"
            className="mb-4 rounded-2xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className='flex flex-col items-center'>
            <h3 className="text-lg font-semibold mb-2">How to setup eSIM activation on iPhone & Android...</h3>
            <div className='flex items-center'>
              <FaYoutube size={30} style={{ color: 'red', marginRight: '5px' }} /> YouTube
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSupport;
