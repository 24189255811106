import React,{useState} from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CreateAccount from "./CreateAccount";
import StepHeader from "./StepHeader";

const Step3 = () => {
  const navigate = useNavigate();

  
  const handleNavigateToDetails = () => {
    navigate('/Step4');
  };
  const handleNavigateback = () => {
    navigate('/Step2');
  };
  
  const handleCancel = () => {
    navigate('/Home');
  };
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
  const handleNavigate = () => {
      setModalIsOpen(true); // Open the modal when "Top-Ups" button is clicked
  };
  return (
<>
    <div className=" flex justify-center items-center h-screen">
    <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2 m-10  relative ">

    <StepHeader/>

      <div className="w-full rounded-11xl bg-powderblue overflow-hidden flex flex-row items-start justify-start gap-[90px] text-center text-5xl text-brand-white">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">1</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
          <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">2</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl [background:linear-gradient(-45deg,_#1298aa,_#29d7b7)] h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
            <div className="relative font-medium">3</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">4</div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">5</div>
            </div>
          </div>
        </div>

      <h1 className="text-2xl font-semibold mt-4">
        Step 2: Download and install your first eSIM
      </h1>
      <div style={{backgroundColor:'#ffffff' }} className="box1 w-full shadow-lg rounded-lg  p-4">
  <p className="text-xl "> 
  Before initiating eSIM installation, there are two straightforward verifications:
  <br></br>
1. Confirm that you or your users possess an unlocked eSIM Compatible device. For a comprehensive
list of the latest eSIM-compatible devices ,    
<a href="https://www.airhubapp.com/info/compatibilityCheck" className="text-blue-700 font-semibold" target="_blank" rel="noopener noreferrer">  please click here.</a>
<br></br>
2. Ensure that your account contains sufficient credit to acquire a data bundle. 
  </p>
</div>

<br></br>
<div 
  style={{ backgroundColor: '#ffffff' }} 
  className="box1 w-full shadow-lg rounded-lg mb-4 p-8 hidden lg:block"
>
  <p className="text-xl">
    Once these checks are complete, you can proceed with purchasing your first data
    bundle and linking it to an eSIM:
    <br />
    1. Navigate to the webstore and indicate your desired country for testing. If your country is not
    listed, it may not be covered. In such cases,  
    <button 
      className="text-blue-700 font-semibold" 
      onClick={handleNavigate}
    >
      please reach
    </button>  
    out to us.
    <br />
    2. Select the preferred bundle size for testing. Larger bundles offer a lower cost per GB.
    <br />
    3. During checkout, purchase your chosen bundle and opt for the "assign to eSIM" feature when
    prompted.
    <br />
    4. Download and scan the QR Code for detailed installation instructions - 
    <a 
      href="https://www.airhubapp.com/info/Installation" 
      className="text-blue-700 font-semibold" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      please click here.
    </a>
    click here for guidance.
    <br />
    5. For information regarding bundle duration and validity after purchase, please get in touch with  
    <button 
      className="text-blue-700 font-semibold" 
      onClick={handleNavigate}
    >
      contact account manager
    </button>  
  </p>
</div>

<br></br>

<div className="absolute bottom-0 left-0 w-full bg-daefef p-8 flex justify-between">
  <button
    className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
    onClick={handleCancel}
  >
    Skip
  </button>
  <div className="flex space-x-4">
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateback}
    >
      Back
    </button>
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateToDetails}
    >
      Next
    </button>
  </div>
</div>
    </div>
    </div>
    
            {/* Modal for displaying Balanceadded component */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Balance Added Modal"
                // Add styles for the modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '0px',
                        borderRadius: '8px',
                        height:'fit-content'
                    },
                }}
            >
                {/* Render the Balanceadded component inside the modal */}
                <CreateAccount />
            </Modal>
            </>
  );
};

export default Step3;
