import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { useNavigate } from 'react-router-dom';
import iota from '../assets/images/iota.svg'
function Login() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading
    const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

    const navigate = useNavigate();

    const handleSignupClick = () => {
        navigate('/Signup');
    };
    const handleForgotclick = () => {
        navigate('/Forgotpassword');
    };
    
    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const handleSubmit = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(`${apiUrl}Authentication/UserLogin`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userName, password })
            });
            const data = await response.json();
    
            if (response.ok) {
                // Store user credentials in localStorage
                localStorage.setItem('token', data.token);
                localStorage.setItem('username', data.data.userID); // Use 'userID' instead of 'userName'
                localStorage.setItem('role', data.data.role);
                localStorage.setItem('partnerCode', data.data.partnerCode);
                localStorage.setItem('Name', data.data.userName);
    
                const logoutTime = Date.now() + 18000000; // Set logout time 30 minutes from now
    
                // Calculate and log the remaining time before logout
                const remainingTime = Math.ceil((logoutTime - Date.now()) / 1000);
                console.log(`Time left to logout: ${remainingTime} seconds`);
    
                // Set a timeout to remove user credentials after 30 minutes
                setTimeout(() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    localStorage.removeItem('role');
                    console.log("Session expired, user logged out");
                    alert("Timeout");
                    navigate("/");
                }, 18000000);
    
                // Navigate based on user role
                if (data.data.role === 'Partner') {
                    navigate('/Step1');
                } else if (data.data.role === 'admin') {
                    navigate('/SuperadminDashboard');
                } else {
                    // Handle other roles or navigate to a default page
                    navigate('/DefaultDashboard');
                }
            } else {
                // Handle error
                setMessage('Login Failed.');
            }
        } catch (error) {
            console.log('error', error);
            setMessage('Email or password Incorrect');
        } finally {
            setLoading(false); // End loading
        }
    };
    
  
    return (
        <div className="login bg-daefef h-screen w-full flex justify-center items-center">
            <div className="rounded-2xl mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 xl:p-6 dark:bg-gray-800 dark:border-gray-700 p-8 m-8" style={{ backgroundColor: "#ffffff" }}>
            
   {/* {/ <h1 style={{ fontSize: '2.5rem' }} className="text-center font-bold mt-8">Welcome to YEdhu</h1> /} */}
                <h4 className="text-center font-semibold text-lg mt-4">Please Sign in to continue </h4> 
                <label htmlFor="email" className="block">Email</label>
                <input style={{ backgroundColor: "#ffffff" }} type="email" placeholder="Email Address" name="email" required className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9" onChange={handleUserNameChange} />
                <br />
                <br />
                <div className="relative">
                <div className="flex justify-between items-center">
    <label htmlFor="Password" className="block">Password</label>
    <button onClick={handleForgotclick} className="flex items-center text-sm text-[#3ea0a3] hover:underline">
        <img src={iota} alt="image" className="mr-1" />
        Forgot password?
    </button>
</div>

    <input style={{ backgroundColor: "#ffffff" }} type={showPassword ? "text" : "password"} placeholder="Enter your Password" name="password" required className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9" onChange={handlePasswordChange} />
    <span className="mt-2 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer" onClick={togglePasswordVisibility}>
        {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />} 
    </span>
</div>

                <p className='text-5xl text-red-600 font-bold  text-center'> {message}</p>

                <div className="mt-8 btn flex items-center justify-between">
                    {/* <button style={{ backgroundColor: "#ffffff" }} className="rounded-full m-2 text-black bg-white-400 w-3/6 px-4 py-4 shadow-md hover:text-blue-400 hover:bg-white transition duration-200 ease-in flex items-center justify-center"         onClick={handleSignupClick}
                    >
                        <FcGoogle className="mr-2" size={30} />
                        Continue With Google
                    </button> */}
                    <button className="rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in" style={{ background: 'linear-gradient(to bottom right, #e8cd4d, #eebc3a, #f1b230, #f8a01c)' }} onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Signing In...' : 'Sign In'}
                    </button>
                </div>
                <h4 className="text-center pt-8 text-9ebbbf">
      Don't have an Account?{' '}
      <span
        className="text-[#2c8686]  font-bold"
        onClick={handleSignupClick}
        style={{ cursor: 'pointer' }}
      >
        Signup
      </span>
    </h4>
            </div>
        </div>
    );
}

export default Login;
