import React,{useState} from "react";
import Notepad from "../assets/images/profile1.svg";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CreateAccount from "./CreateAccount";
import StepHeader from "./StepHeader";
import { RxCross2 } from "react-icons/rx";

const Step1 = () => {
  const username =localStorage.getItem('Name');
  const navigate = useNavigate();
  const handleNavigateToDetails = () => {
    navigate('/Step2');
  };
  const handleCancel = () => {
    navigate('/Home');
    
  };


  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
    const handleNavigate = () => {
        setModalIsOpen(true); // Open the modal when "Top-Ups" button is clicked
    };
    
  
  return (
    <>
    <div className=" flex justify-center items-center h-screen">
    <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2 m-10  relative ">

    <div className="flex justify-between  items-center mb-4">
  <h1 className="text-5xl font-bold">      <StepHeader/>
  </h1>
<div className="flex items-center space-x-4">
       <div className="relative">
     <button
          className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
          onClick={handleCancel}        >
<RxCross2 />
</button>
</div>

  </div>
</div>
   
    {/* <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2  relative "> */}

        {/* <div className="w-full rounded-11xl bg-powderblue overflow-hidden flex flex-row items-start justify-start gap-[90px] text-center text-5xl text-brand-white">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl [background:linear-gradient(-45deg,_#1298aa,_#29d7b7)] h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
                <div className="relative font-medium">1</div>
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
                <div className="relative font-medium">2</div>
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start">
              <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
                <div className="relative font-medium">3</div>
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start">
              <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
                <div className="relative font-medium">4</div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
                <div className="relative font-medium">5</div>
              </div>
            </div>
          </div> */}
      <h1 className="text-2xl font-semibold mt-2">
      Excited to Be Your Connectivity Crew! 
   </h1>


   <div className="content flex flex-col md:flex-row justify-between p-2">
  <div className="sidebar rounded-full md:w-[38rem] md:h-[20rem] flex justify-center items-center bg-white mb-4 md:mb-0 hidden md:flex">
    <img
      src={Notepad}
      alt="Notepad"
      className="rounded-full"
      style={{ maxWidth: '100%', height: '15rem' }}
    />
  </div>

  <div className="main bg-white py-11 ml-0 md:ml-5 rounded-3xl">
    <p className="text-lg ml-0 md:ml-16">
      Hi, {username}
      <br /><br />
      Thanks a bunch for choosing us to handle your connectivity needs! We're super excited to be your go-to crew for eSIM solutions. Rest assured, we're all about giving you top-notch service and the best deals in town.
      <br /><br />
      Feel free to reach out to your dedicated   <span></span>
      <button 
        className="text-blue-700 font-bold"
        onClick={handleNavigate}
      > 
        contact account manager 
      </button> 
      <span></span>  to discuss your specific needs.
      <br /><br />
      Looking forward to rockin' it together,
      
    </p>
  </div>
</div>

{/* 
      <div className="absolute bottom-0 left-0  w-full bg-daefef p-8 flex justify-between">
        <button
          className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
          onClick={handleCancel}        >
          Skip
        </button>
        <button className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"            onClick={handleNavigateToDetails}
>
          Next
        </button>
      </div> */}
    </div>
    </div>
    
            {/* Modal for displaying Balanceadded component */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Balance Added Modal"
                // Add styles for the modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '0px',
                        borderRadius: '8px',
                        height:'fit-content'
                    },
                }}
            >
                {/* Render the Balanceadded component inside the modal */}
                <CreateAccount />
            </Modal>
            </>
  );
};

export default Step1;
