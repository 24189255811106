import React from 'react'
import New from '../assets/images/News&update.svg';
function News() {
  return (
    <div className=" flex justify-center items-center h-screen">
        <img src={New} alt='New and update'/>

    </div>
  )
}

export default News