import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SuperadminTicket = () => {
  const [tickets, setTickets] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  useEffect(() => {
    const partnerCode = localStorage.getItem('partnerC'); // Adjust according to your session storage
    const token = localStorage.getItem('token'); // Adjust according to your session storage

    const fetchTickets = async () => {
      try {
        const response = await axios.get(
            `https://scjapi.clay.in/api/ESIMGoAdmin/Get_TicketsDatailsPartnerWise?partnerCode=${partnerCode}`,
            {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.isSuccess) {
          setTickets(response.data.ticketList);
        }
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      }
    };

    fetchTickets();
  }, []);

  const handleOpenClick = (ticketId) => {
    setSelectedTicketId(ticketId);
    setShowPopup(true);
  };

  const handleConfirmClose = async () => {
    const token = localStorage.getItem('token'); // Adjust according to your session storage

    try {
      const response = await axios.post(
        'https://scjapi.clay.in/api/ESIMGoAdmin/Update_partner_ticketstatus',
        {
          ticket_id: selectedTicketId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.isSuccess) {
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.ticket_id === selectedTicketId
              ? { ...ticket, status: 'Closed' }
              : ticket
          )
        );
        setShowPopup(false);
      }
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  };

  const handleCancelClose = () => {
    setShowPopup(false);
    setSelectedTicketId(null);
  };

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Ticket Details</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Ticket ID</th>
              <th className="py-2 px-4 border-b">ICCID</th>
              <th className="py-2 px-4 border-b">Description</th>
              <th className="py-2 px-4 border-b">Partner Code</th>
              <th className="py-2 px-4 border-b">Issue Department</th>
              <th className="py-2 px-4 border-b">Amount</th>
              <th className="py-2 px-4 border-b">Create Date</th>
              <th className="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {tickets.length > 0 ? (
            tickets.map((ticket, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{ticket.ticket_id || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.iccid || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.descrition || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.partnercode || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.issuedepartment || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.amount || 'NA'}</td>
                <td className="py-2 px-4 border-b">{ticket.date || 'NA'}</td>

                <td className="py-2 px-4 border-b">
                  {ticket.status === 'Open' ? (
                    <button
                      className="bg-blue-500 text-white py-1 px-2 rounded"
                      onClick={() => handleOpenClick(ticket.ticket_id)}
                    >
                      {ticket.status}
                    </button>
                  ) : (
                    ticket.status || 'NA'
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">NA</td>

            </tr>
          )}
        </tbody>
        </table>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>Are you sure you want to close this ticket?</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-green-500 text-white py-1 px-2 rounded mr-2"
                onClick={handleConfirmClose}
              >
                Yes
              </button>
              <button
                className="bg-red-500 text-white py-1 px-2 rounded"
                onClick={handleCancelClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperadminTicket;
