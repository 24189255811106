import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure axios is installed

function Yourticket() {
  const [ticketList, setTicketList] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(""); // 'date', 'orderid', 'ticketid'
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    // Fetch data from API when component mounts
    const fetchData = async () => {
      try {
        const sessionToken = localStorage.getItem("token");
        const partnerCode = localStorage.getItem("partnerCode");

        const response = await axios.get(
          `${apiUrl}ESIM/Get_TicketsDatailsPartnerWise?partnerCode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        

        if (response.data.isSuccess) {
          setTicketList(response.data.ticketList);
          setFilteredTickets(response.data.ticketList); // Initialize filtered tickets with all tickets
        } else {
          console.error("API request failed:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once on component mount

  // Pagination
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle search
  const handleSearch = () => {
    let filtered = ticketList;

    if (searchTerm && searchType) {
      if (searchType === "date") {
        filtered = ticketList.filter((ticket) =>
          ticket.createdate.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else if (searchType === "orderid") {
        filtered = ticketList.filter((ticket) =>
          ticket.iccid.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else if (searchType === "ticketid") {
        filtered = ticketList.filter((ticket) =>
          ticket.ticketid.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
    }

    setFilteredTickets(filtered);
    setCurrentPage(1); // Reset pagination to first page after search
  };

  const handleDescriptionClick = (description) => {
    setModalContent(description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  return (
    <>
      <div style={{ backgroundColor: "#ffffff" }} className="w-full p-8">
        <h1 className="text-5xl mb-2 text-center font-bold">
          View Create Tickets
        </h1>

        {/* Search bar */}
        <div className="flex justify-end mb-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md mr-2"
          />
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md"
          >
            <option value="">Search by...</option>
            {/* <option value="date">Date</option> */}
            <option value="orderid">Order ID</option>
            <option value="ticketid">Ticket ID</option>
          </select>
          <button
            onClick={handleSearch}
            className="px-4 py-2 text-white rounded-md ml-2"
            style={{
              background:
                "linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)",
            }}
          >
            Search
          </button>
        </div>

        {currentTickets.length === 0 ? (
          <p className="text-center">No tickets found.</p>
        ) : (
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="py-2">Ticket ID</th>
                <th className="py-2">Order ID</th>
                <th className="py-2">Issue</th>
                <th className="py-2">Amount</th>
                <th className="py-2">Create Date</th>
                <th className="py-2">Status</th>
                <th className="py-2">Description</th>
              </tr>
            </thead>
            <tbody>
              {currentTickets.map((ticket, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 text-center">{ticket.ticket_id}</td>
                  <td className="py-2 text-center">{ticket.iccid}</td>
                  <td className="py-2 text-center">{ticket.issuedepartment}</td>
                  <td className="py-2 text-center">{ticket.amount}</td>
                  <td className="py-2 text-center">{ticket.created_date}</td>
                  <td className="py-2 text-center">{ticket.status}</td>
                  <td className="py-2 text-center">
                    <button
                      onClick={() => handleDescriptionClick(ticket.descrition)}
                      className="rounded-full m-2 text-white  px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                      style={{
                        background:
                          "linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)",
                      }}
                    >
                      Description
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination */}
        {filteredTickets.length > ticketsPerPage && (
          <div className="mt-4 flex justify-center">
            {Array.from(
              { length: Math.ceil(filteredTickets.length / ticketsPerPage) },
              (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`px-3 py-1 mx-1 ${
                    currentPage === i + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              )
            )}
          </div>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Description</h2>
            <p>{modalContent}</p>
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Yourticket;
