import React,{useState} from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CreateAccount from "./CreateAccount";
import StepHeader from "./StepHeader";

const Step2 = () => {
  const navigate = useNavigate();

  
  const handleNavigateToDetails = () => {
    navigate('/Step3');
  };
  const handleNavigateback = () => {
    navigate('/Step1');
  };
  
  const handleCancel = () => {
    navigate('/Home');
  };
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
  const handleNavigate = () => {
      setModalIsOpen(true); // Open the modal when "Top-Ups" button is clicked
  };
  const handletotop =()=>{
    navigate('/TopUps')
  }
  return (
    <>
    <div className=" flex justify-center items-center h-screen">
    <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2 m-10  relative ">

    <StepHeader/>

      <div className="w-full rounded-11xl bg-powderblue overflow-hidden flex flex-row items-start justify-start gap-[90px] text-center text-5xl text-brand-white">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">1</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl [background:linear-gradient(-45deg,_#1298aa,_#29d7b7)] h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">2</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">3</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">4</div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">5</div>
            </div>
          </div>
        </div>
      <h1 className="text-2xl font-semibold mt-2"> Step 1: Top-Up your Account </h1>
      <div  className="box1 w-full bg-#e9f8f9  rounded-lg p-2">
  <p className="text-xl "> 
  We utilize a prepaid Wallet system, where the cost is deducted upon purchasing a new bundle. To
acquire eSIM bundles, you must first top up your Wallet.
<br></br>
Data bundles are accessible through either the webstore or API. These bundles can be utilized for
new eSIM activations or to supplement existing ones.  
  </p>
</div>

<div className="box1 w-full bg-white rounded-2xl p-8 hidden md:flex flex-col md:flex-row justify-between items-center">
  <div style={{ marginLeft: '152px' }}>
    <p className="text-5xl font-bold">Current Account Balance</p>
    <p className="text-5xl font-bold text-center" style={{ color: '#137676' }}>${5000}</p>
  </div>
  <div>
    <button 
      style={{ backgroundColor: '#117575' }} 
      className="bg-[#117575] text-white px-6 py-5 rounded-full font-bold" 
      onClick={handletotop}
    >
      Top-Up Account
    </button>
  </div>
  <div className="flex flex-col md:flex-row justify-between items-center">
    <div style={{ marginRight: '152px' }}>
      <p className="text-5xl font-bold">Daily Top-Up Limit</p>
      <p className="text-5xl font-bold text-center" style={{ color: '#fa473c' }}>${2000}</p>
    </div>
  </div>
</div>


<div className="box1 w-full bg-#e9f8f9  rounded-lg mb-4 p-2">
  <p className="text-xl "> 
  With Airhub, there are no setup fees, recurring charges, or download fees associated with eSIMs.
You simply pay for your data bundles, and we manage the rest.
Once your account is topped up, you can proceed with purchasing and installing your first eSIM.
<p></p>
Airhub welcomes BACS and bank transfers as well. Additionally, we offer test credits to aid you in
your eSIM journey as part of our review process. Please reach out to your account manager to take
advantage  <span><button className="text-blue-700 font-semibold"  onClick={handleNavigate}> contact account manager</button></span> </p>

</div>




<div className="absolute bottom-0 left-0 w-full bg-daefef p-8 flex justify-between">
  <button
    className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
    onClick={handleCancel}
  >
    Skip
  </button>
  <div className="flex space-x-4">
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateback}
    >
      Back
    </button>
    <button
      className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"
      onClick={handleNavigateToDetails}
    >
      Next
    </button>
  </div>
</div>
    </div>
    </div>
    
            {/* Modal for displaying Balanceadded component */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Balance Added Modal"
                // Add styles for the modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '0px',
                        borderRadius: '8px',
                        height:'fit-content'
                    },
                }}
            >
                {/* Render the Balanceadded component inside the modal */}
                <CreateAccount />
            </Modal>
            </>
  );
};

export default Step2;
