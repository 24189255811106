import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import card from "../assets/images/Card.svg";
import paypal from "../assets/images/Paypal.svg";
import bank from "../assets/images/Bank.svg";
import CreateAccount from "./CreateAccount";
import iota from "../assets/images/iota.svg";
import axios from "axios";
function AddmoreBalance() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [amount, setAmount] = useState("");
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const handleCrossClick = () => {
    navigate("/home");
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handlePayClick = () => {
    const numericAmount = parseFloat(amount.replace("$", "").replace(/,/g, ""));
    if (numericAmount > 5) {
      localStorage.setItem("amount", amount);
      setShowModal(true);
    } else {
      alert("Please enter a valid amount greater than $5 to proceed.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleProceedClick = () => {
    setShowModal(false);
    setShowSecondModal(true);
  };

  const handleAmountClick = (value) => {
    setAmount(`${value}`);
  };

  const handleInputChange = (event) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    setAmount(`${value}`);
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      const sessionToken = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");
  
      try {
        if (!sessionToken || !partnerCode) {
          console.error(
            "Session token or partner code missing",
            sessionToken,
            partnerCode
          );
          return;
        }
  
        const response = await axios.get(
          `${apiUrl }ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWalletData(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };
  
    fetchWalletData();
  }, [sessionToken, partnerCode]);
  
  return (
    <>
      <div className="bg-[#ffffff] rounded-2xl p-4">
        <div className="content flex-1 rounded-r-lg overflow-auto">
          <div className="flex justify-between items-center">
            <h2 className="text-5xl font-semibold">Wallet</h2>
            <div className="flex items-center  ">
              <p className="mr-8">Balance</p>
              <RxCross2
                size={25}
                onClick={handleCrossClick}
                className="cursor-pointer  border-2"
              />
            </div>
          </div>
          <div className="flex justify-end items-center">
            {walletData ? (
              <span className="text-[#409090] text-2xl font-bold mr-16">{`$${walletData.balance}.00 `}</span>
            ) : (
              <p className="text-lg text-gray-600">Loading...</p>
            )}
          </div>
        </div>

        <div className="flex flex-col items-start p-4">
          <label htmlFor="amountInput" className="mb-2">
            Add Amount
          </label>
          <div className="relative w-full">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
              $
            </span>
            <input
              type="text"
              id="amountInput"
              className="w-full pl-12 pr-3 py-3 border-2 border-black-200 rounded-2xl focus:outline-none focus:border-gray-500"
              placeholder="Enter amount"
              value={amount}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-4">
            <span
              className="border-2 rounded-full p-2 cursor-pointer mr-2"
              onClick={() => handleAmountClick("1000")}
            >
              $1000
            </span>
            <span
              className="border-2 rounded-full p-2 cursor-pointer mr-2"
              onClick={() => handleAmountClick("1500")}
            >
              $1500
            </span>
            <span
              className="border-2 rounded-full p-2 cursor-pointer mr-2"
              onClick={() => handleAmountClick("2000")}
            >
              $2000
            </span>
            <span
              className="border-2 rounded-full p-2 cursor-pointer mr-2"
              onClick={() => handleAmountClick("2500")}
            >
              $2500
            </span>
          </div>
        </div>

        <button
          className=" bg-[#117575] rounded-2xl m-2 text-white font-bold w-full px-4 py-4 shadow-md hover:bg-[#057b74] transition duration-200 ease-in"
          onClick={handlePayClick}
        >
          Pay ${amount}
        </button>

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-3/4 md:w-1/2">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-5xl font-bold">Payment Options</h2>
                <ImCross
                  size={25}
                  onClick={closeModal}
                  className="cursor-pointer"
                />
              </div>
              <div className="bg-[#ffffff] p-4 rounded-b-xl">
                <h3 className="text-sm mb-2">Credit/Debit card</h3>
                <div
                  className={`flex items-center mb-4 border-2 p-2 rounded-2xl pb-2 ${
                    selectedOption === "card"
                      ? "border-green-500 shadow-lg"
                      : ""
                  }`}
                >
                  <img src={card} alt="Card" className="w-10 h-10" />
                  <h1 className="ml-2 text-black font-bold">Pay Via Card</h1>
                  <h1 className="ml-auto">Coming Soon</h1>
                </div>
                <h3 className="text-sm mb-2">Online payment</h3>
                <div
                  className={`flex items-center mb-4 border-2 p-2 rounded-2xl pb-2 ${
                    selectedOption === "paypal"
                      ? "border-green-500 shadow-lg"
                      : ""
                  }`}
                >
                  <img src={paypal} alt="Paypal" className="w-10 h-10" />
                  <h1 className="ml-2 text-black font-bold">Pay Via Paypal</h1>
                  <h1 className="ml-auto">Coming Soon</h1>
                </div>
                <h3 className="text-sm mb-2">Bank Transfer</h3>
                <div
                  className={`flex items-center mb-4 border-2 p-2 rounded-2xl ${
                    selectedOption === "bank"
                      ? "border-green-500 shadow-lg"
                      : ""
                  }`}
                >
                  <img src={bank} alt="Bank" className="w-10 h-10" />
                  <h1 className="ml-2 text-black font-bold">
                    Pay Via Bank Transfer
                  </h1>
                  <input
                    type="radio"
                    className={`ml-auto w-6 h-6 border-2 border-gray-300 rounded-full cursor-pointer appearance-none ${
                      selectedOption === "bank" ? "border-green-500" : ""
                    }`}
                    checked={selectedOption === "bank"}
                    onChange={() => handleOptionChange("bank")}
                    style={{
                      backgroundColor:
                        selectedOption === "bank" ? "#117575" : "transparent",
                    }}
                  />
                </div>
                {selectedOption && (
                  <form className="mt-4">
                    {selectedOption === "card" && (
                      <>
                        <div className="mb-4">
                          <label
                            htmlFor="cardNumber"
                            className="block text-gray-700"
                          >
                            Card Number
                          </label>
                          <input
                            type="text"
                            id="cardNumber"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="1234 5678 9123 4567"
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="expiryDate"
                            className="block text-gray-700"
                          >
                            Expiry Date
                          </label>
                          <input
                            type="text"
                            id="expiryDate"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="MM/YY"
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="cvv" className="block text-gray-700">
                            CVV
                          </label>
                          <input
                            type="text"
                            id="cvv"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            placeholder="123"
                          />
                        </div>
                      </>
                    )}
                    {selectedOption === "paypal" && (
                      <div className="mb-4">
                        <label
                          htmlFor="paypalEmail"
                          className="block text-gray-700"
                        >
                          Paypal Email
                        </label>
                        <input
                          type="email"
                          id="paypalEmail"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="email@example.com"
                        />
                      </div>
                    )}
                    {selectedOption === "bank" && (
                      <div className="flex text-green-700 font-bold">
                        <img src={iota} alt="image" className="mr-1" />
                        Please reach out to your account manager
                      </div>
                    )}
                  </form>
                )}
              </div>
              <button
                className="bg-[#117575] rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-[#1cbebe] transition duration-200 ease-in"
                onClick={handleProceedClick}
              >
                Proceed
              </button>
            </div>
          </div>
        )}

        {showSecondModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg w-3/4 md:w-1/2">
              <CreateAccount />
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-4 mt-8 rounded-lg w-full">
        <div>
          <h2 className="text-2xl font-bold mb-4">Available Payment Methods</h2>
        </div>
        <div className="bg-[#ffffff] rounded-full flex flex-wrap gap-32">
          <div
            className={`flex items-center p-4 rounded-2xl ${
              selectedOption === "card"
                ? "border-green-500 shadow-lg"
                : "border-gray-300"
            }`}
          >
            <img src={card} alt="Card" className="w-12 h-12" />
            <h1 className="ml-4 text-black font-bold">Debit/Credit Cards</h1>
          </div>

          <div
            className={`flex items-center p-4 rounded-2xl ${
              selectedOption === "paypal"
                ? "border-green-500 shadow-lg"
                : "border-gray-300"
            }`}
          >
            <img src={paypal} alt="Paypal" className="w-12 h-12" />
            <h1 className="ml-4 text-black font-bold">Paypal Payment</h1>
          </div>

          <div
            className={`flex items-center p-4 rounded-2xl ${
              selectedOption === "bank"
                ? "border-green-500 shadow-lg"
                : "border-gray-300"
            }`}
          >
            <img src={bank} alt="Bank" className="w-12 h-12" />
            <h1 className="ml-4 text-black font-bold">Bank Transfer</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddmoreBalance;
