import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { ImCross } from "react-icons/im";
import tick from '../assets/images/ticksignup.svg'
function UserProfile() {
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State to track admin role
  const [isPartner, setIsPartner] = useState(false); // State to track partner role
  const [partnerDetails, setPartnerDetails] = useState({
    contactperson: '',
    phoneno: '',
    email: '',
    companyname: '',
    password: '',
    address: '',
    partnerid: '',
    balance: '',
    partnercode: '',
    discount: ''
  }); // Initialize with default values
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [editedFields, setEditedFields] = useState({}); // State to track edited fields
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem('token');
      const storedPartnerCode = localStorage.getItem('partnerCode');
      const storedUserName = localStorage.getItem('Name');
      const storedRole = localStorage.getItem('role'); // Fetch user role

      if (storedSessionToken && storedPartnerCode && storedUserName && storedRole) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
        setUserName(storedUserName);
        setIsAdmin(storedRole === 'admin'); // Check if user is admin
        setIsPartner(storedRole === 'Partner'); // Check if user is partner
      } else {
        console.error('Session data not found');
      }
    };

    const fetchPartnerDetails = async () => {
      try {
        const url = `${apiUrl}ESIM/partner_details?flag=2&partnercode=${partnerCode}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${sessionToken}` // Include session token in headers
          }
        });
        const data = await response.json();
        if (data.isSuccess && data.partnerlist.length > 0) {
          setPartnerDetails(data.partnerlist[0]); // Assuming only one partner detail is fetched
        } else {
          console.error('Error fetching partner details');
        }
      } catch (error) {
        console.error('Error fetching partner details:', error);
      }
    };

    fetchSessionData();
    if (partnerCode && sessionToken) {
      fetchPartnerDetails();
    }
  }, [partnerCode, sessionToken]);

  const handleFieldChange = (e) => {
    const { id, value } = e.target;
    setPartnerDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value
    }));
    setEditedFields((prevFields) => ({
      ...prevFields,
      [id]: true
    }));
  };

  const handleSave = async () => {
    if (Object.keys(editedFields).length === 0) {
      alert('No changes detected.');
      return;
    }
  
    const confirmation = window.confirm(
      'You have unsaved changes. Are you sure you want to save these changes?'
    );
  
    if (!confirmation) {
      return;
    }
  
    const payload = {
      partnerCode: partnerCode,
      fullName: partnerDetails.contactperson,
      phoneNumber: partnerDetails.phoneno,
      emailId: partnerDetails.email,
      password: partnerDetails.password,
      companyName: partnerDetails.companyname,
      address: partnerDetails.address,
      countryId: 0,
      stateId: 0,
      cityId: 0,
      zipCode: "string"
    };
  
    try {
      const apiUrl = `${process.env.REACT_APP_BASE_URL}ESIM/UpdateRegisterPartner`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionToken}` // Include session token in headers
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      if (data.isSuccess) {
        setShowPopup(true); // Show popup on successful update
        setEditedFields({}); // Reset edited fields after successful save
        alert('Update successful: '); // Show success response
      } else {
        alert('Update failed: ' + data.errorMessage); // Show error response
        console.error('Error updating partner details:', data.errorMessage);
      }
    } catch (error) {
      alert('Error updating partner details: ' + error.message); // Show error message
      console.error('Error updating partner details:', error);
    }
  };
  
  return (
    <>
      <div style={{ backgroundColor: '#ffffff' }} className="main-content flex-1 bg-teal-100 overflow-auto rounded-2xl h-fit-content p-8">
        <h1 className="text-5xl font-bold mb-4 text-[#197979]">Edit Profile</h1>

        {/* Partner Details Display */}
        {partnerDetails && (
          <>
            <div className="flex mb-4">
              <div className="w-1/2 pl-2">
                <label htmlFor="contactperson" className="text-black block mb-1">First Name</label>
                <input
                  type="text"
                  id="contactperson"
                  value={partnerDetails.contactperson}
                  name="contactperson"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="phoneno" className="text-black block mb-1">Phone Number </label>
                <input
                  type="text"
                  id="phoneno"
                  value={partnerDetails.phoneno}
                  name="phoneno"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pl-2">
                <label htmlFor="email" className="text-black block mb-1">Email</label>
                <input
                  type="text"
                  id="email"
                  value={partnerDetails.email}
                  name="email"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="companyname" className="text-black block mb-1">Company Name</label>
                <input
                  type="text"
                  id="companyname"
                  value={partnerDetails.companyname}
                  name="companyname"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pl-2">
                <label htmlFor="password" className="text-black block mb-1">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={partnerDetails.password}
                    name="password"
                    className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                    style={{ borderColor: '#91b1b5' }}
                    onChange={handleFieldChange}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="address" className="text-black block mb-1">Address</label>
                <input
                  type="text"
                  id="address"
                  value={partnerDetails.address}
                  name="address"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                  onChange={handleFieldChange}
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pl-2">
                <label htmlFor="partnerid" className="text-black block mb-1">Partner ID</label>
                <input
                  type="text"
                  id="partnerid"
                  value={partnerDetails.partnerid}
                  name="partnerid"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="balance" className="text-black block mb-1">Balance</label>
                <input
                  type="text"
                  id="balance"
                  value={partnerDetails.balance}
                  name="balance"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                />
              </div>
            </div>
            <div className="flex mb-4">
              <div className="w-1/2 pl-2">
                <label htmlFor="partnercode" className="text-black block mb-1">Partner Code</label>
                <input
                  type="text"
                  id="partnercode"
                  value={partnerDetails.partnercode}
                  name="partnercode"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="discount" className="text-black block mb-1">Discount (%)</label>
                <input
                  type="text"
                  id="discount"
                  value={partnerDetails.discount}
                  name="discount"
                  className="w-full p-3 border-2 border-#91b1b5 rounded-2xl"
                  style={{ borderColor: '#91b1b5' }}
                />
              </div>
            </div>
          </>
        )}

        {/* Save Button */}
        <div className="flex justify-end mt-8">
          <button
            type="button"
            onClick={handleSave}
            className="button bg-orange-500 text-white px-8 py-4 font-semibold rounded-full"
          >
            Update
          </button>
        </div>

        {/* Popup Message */}
        {showPopup && (
       <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
       <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full relative">
            
       <button onClick={() => setShowPopup(false)}
                  className="absolute top-4 right-4 px-4 py-2  text-white rounded-lg hover:bg-[#20bfb2]"
                  >      <ImCross size={20} color='grey' />
</button>
<div className="flex flex-col items-center justify-center h-full">
<img src={tick} alt='ticksignup' className="mb-4" />
      <p className="text-5xl font-bold">Update Complete</p>
            </div>
          </div>
          </div>

        )}
      </div>
    </>
  );
}

export default UserProfile;
