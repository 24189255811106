import React, { useState } from 'react';
import Modal from 'react-modal';
import { CiMicrochip } from "react-icons/ci";
import { CircleFlag } from 'react-circle-flags';
import { useNavigate } from 'react-router-dom';

const Topups = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
    const handleNavigateToDetails = () => {
        setModalIsOpen(true); // Open the modal when "Top-Ups" button is clicked
    };

    return (
        <>
        <div style={{ backgroundColor: '#ffffff',marginBottom:'5px' }} className="p-2 rounded-3xl ">
            <h1 style={{fontWeight: 'bold', fontSize: '2rem'}}>Top-Up Account</h1>
        <br></br>
            <div className="flex flex-wrap -mx-3 mb-2">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                    Top Up Amount(min $250.00)
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-country" type="password" placeholder="Enter your Address" />
                            </div>
                        </div>

                        <div style={{boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '1rem'}} className="main-content p-2">
                    <div className="bg-transparent h-fit-content rounded-lg mt-5">
                        <h1 className='justify-space text-5xl font-bold'>Billing Address</h1>
                        <br></br>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                    First Name
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-200 text-gray-700 borderSignup rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Sameer" />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                    Last Name
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-800 text-gray-700 border border-gray-200 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500" id="grid-last-name" type="text" placeholder="Hussain" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                    Address Line 1*
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-country" type="password" placeholder="Enter your Address" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                    Address Line 2*
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-country" type="password" placeholder="Enter your Address" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                    Town City*
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-200 text-gray-700 borderSignup rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Town/City" />
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                    Postcode/Zip
                                </label>
                                <input style={{ backgroundColor: '#ffffff', border: '1px solid black' }} className="appearance-none block w-full bg-gray-800 text-gray-700 border border-gray-200 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500" id="grid-last-name" type="text" placeholder="Zip code" />
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                                    Address
                                </label>
                                <div className='flex justify-startS items-center '><CircleFlag countryCode="fr" width='3%' className="mr-2" />Italy</div>
                            </div>
                        </div>
                        <div className="relative text-xs font-medium text-brand-black">
              <p className="m-0">* Required Field</p>
              <p className="m-0">{`** Registered Country can be changed in Billing Settings `}</p>
            </div>
                    </div>
                </div>
            </div>

            <div style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }} className="card-content flex justify-between items-center">
                <div style={{ background: 'linear-gradient(to bottom right, #91e6da, #91e6da, #91e6da, #91e6da)', border: 'thin solid white' }} className="credit-card w-1/2 p-4 rounded-lg shadow-md ml-5">
                    <div className="card-number">
                        <CiMicrochip size={75} style={{ color: 'white' }} />
                    </div>
                    <div className="card-info">
                        <div>
                            <p className='flex items-center justify-center p-3 text-white'>XXXX-XXX-XXX-XXXX</p>
                        </div>
                        <div className="names flex justify-between mt-2">
                            <div>
                                <label htmlFor="card-expiry" className="label text-white">Cardholder</label>
                                <p className="text-white">Sameer</p>
                            </div>
                            <div>
                                <label htmlFor="card-type" className="label text-white">Expiry</label>
                                <p className="text-white">MM/YY</p>
                            </div>
                            <div>
                                <label htmlFor="card-cvv" className="label text-white">CVV</label>
                                <p className="text-white">123</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-section w-1/2 p-4">
                    <label htmlFor="number" className="label text-white">Card Number</label>
                    <input type="number" placeholder="XXXX-XXX-XXX-XXXX" name="number" required className="w-full px-4 py-2 border-2 border-white rounded-full bg-transparent mt-2 text-white placeholder-white" />
                    <br />
                    <div className="names flex justify-between mt-2">
                        <div>
                            <label htmlFor="expiry" className="label text-white">Expiry Date</label>
                            <input type="number" placeholder="MM/YY" name="expiry" required className="w-full px-4 py-2 border-2 border-white rounded-full bg-transparent text-white placeholder-white" />
                        </div>
                        <div>
                            <label htmlFor="cvv" className="label text-white">CVV</label>
                            <input type="number" placeholder="XXX" name="cvv" required className="w-full px-4 py-2 border-2 border-white rounded-full bg-transparent text-white placeholder-white" />
                        </div>
                    </div>
                    <label htmlFor="verifypassword" className="label text-white">Cardholder Name</label>
                    <input type="text" placeholder="Sameer Hussain" name="verifypassword" required className="w-full px-4 py-2 border-2 border-white rounded-full bg-transparent mt-2 text-white placeholder-white" />
                    <br />
                </div>
            </div>
            <div className="self-stretch bg-brand-white flex flex-col items-start justify-center p-10">
                <div className="self-stretch flex flex-row items-center justify-start">
                    <div className="flex-1 flex flex-row items-center justify-start gap-[20px]">
                        <div className="relative font-medium">
                            <p className="m-0">Total</p>
                            <p className="m-0 text-xl">New Balance</p>
                        </div>
                        <div className="overflow-hidden flex flex-row items-start justify-center py-0 px-5">
                            <div className="relative font-medium">
                                <p className="m-0">$500</p>
                                <p className="m-0 text-xl">$2000</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 overflow-hidden flex flex-row items-center justify-end gap-[20px] text-lg">
                        <div className="rounded-11xl bg-brand-gray h-[60px] flex flex-row items-center justify-start py-2 px-6 box-border">
                            <b className="relative">Cancel</b>
                        </div>
                        <div className="rounded-11xl [background:linear-gradient(-45deg,_#ff8e09,_#e1dc5c)] h-[60px] flex flex-row items-center justify-start py-2 px-6 box-border text-brand-white">
                            <button onClick={handleNavigateToDetails}>Top-Ups</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for displaying Balanceadded component */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Balance Added Modal"
                // Add styles for the modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '0px',
                        borderRadius: '8px',
                        height:'fit-content'
                    },
                }}
            >
                {/* Render the Balanceadded component inside the modal */}
                <Balanceadded />
            </Modal>
        </>
    );
}

export default Topups;

function Balanceadded() {
    return (
        <div className="w-[fit-content] relative rounded-xl bg-brand-white overflow-hidden flex flex-col items-center justify-center  pb-[60px] box-border gap-[40px] text-center text-21xl text-brand-black font-p4">
            <div className="self-stretch flex flex-col items-center justify-center">
                <div className="self-stretch flex flex-row items-start justify-end">
                    {/* <img
                        className="w-[60px] relative rounded-11xl h-[60px] overflow-hidden shrink-0"
                        alt=""
                        src="/close-button.svg"
                    /> */}
                </div>
                <img className="w-60 relative h-60" alt="" src="/tick.svg" />
            </div>
            <div className="flex flex-col items-center justify-start">
                <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
                    <div className="relative font-semibold">Balance Added</div>
                    <div className="w-[505px] relative text-lg inline-block">
                        Lorem ipsum dolor sit amet consectetur. Varius pulvinar rutrum
                        gravida scelerisque nunc platea accumsan.
                    </div>
                </div>
            </div>
        </div>
    );
}
