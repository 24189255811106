import React, { useState, useEffect, useRef } from 'react';
import logoimage from "../assets/images/10337609.png";
import { CiBellOn } from "react-icons/ci";
import { MdOutlineQuestionMark } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { RiArrowDropDownLine } from "react-icons/ri";
import Modal from 'react-modal';
import Step1 from "../pages/Step1";

const Navbar = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
  const handleNavigate = () => {
    navigate('/HelpSupport');
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all session data
    navigate('/'); // Navigate to home page after logout
  };
  const handleProfile  = () => {
    navigate('/UserProfile'); // Navigate to home page after logout
  };

  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const storedName = localStorage.getItem('Name');
    const storedUsername = localStorage.getItem('username');
    
    if (storedName) {
      setName(storedName);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }

    // Event listener to close dropdowns on click outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
        setIsMessagesOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <nav style={{ backgroundColor: '#daefef'}} className="mx-2 px-2 py-2.5 rounded">
      <div className="flex justify-between items-center mx-auto pt-3">
        <div className="flex justify-between items-center p-4 w-full">
          <div className="main-nav-left font-bold text-lg"></div>
          <div className="main-nav-right flex items-center">
            <div className="flex items-center relative">
              {/* <div className="main-nav-right-icons mr-4 bg-white rounded-full p-2 relative" onClick={() => setIsMessagesOpen(!isMessagesOpen)}>
                <CiBellOn className="text-lg" />
                <div className="badge absolute -top-1 -right-1 bg-red-500 text-white w-4 h-4 rounded-full flex items-center justify-center">4</div>
              </div> */}
              <div className="main-nav-right-icons mr-4 bg-white rounded-full p-2"onClick={handleNavigate} >
                <MdOutlineQuestionMark className="text-lg" />
              </div>
              {/* <div className="main-nav-right-icons  mr-4 bg-white rounded-full p-2">
                <CiUser className="text-lg" />
              </div> */}
              <div className="main-nav-right-icons user flex items-center relative" ref={dropdownRef}>
                <div className="w-auto h-8 rounded-full mr-2 bg-gradient-to-br from-teal-400 to-teal-600 flex items-center justify-center p-6">
                  <img
                    src={logoimage}
                    className="w-6 h-6 rounded-full"
                    alt="User Avatar"
                  />
                  <div className="text-white font-bold ml-2 flex items-center">
                    {/* <button onClick={handleNavigateToDetails}>{name}</button> */}
                    <button>{name}</button>
                    <RiArrowDropDownLine size={40} onClick={() => setShowLogout(!showLogout)} />
                  </div>
                </div>
                {showLogout && (
  <div className="absolute top-12 right-0 bg-white border border-gray-300 rounded-md p-2 shadow-lg z-50">
    <button
      className="block w-full text-left py-2 px-4 text-gray-800 hover:bg-gray-200"
      onClick={handleProfile}
    >
      Profile
    </button>
    <hr />
    <button
      className="block w-full text-left py-2 px-4 text-gray-800 hover:bg-gray-200"
      onClick={handleLogout}
    >
      Logout
    </button>
  </div>
)}

              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-end pr-4">
          <Toggle />
        </div> */}
      </div>

      {/* Messages */}
      {isMessagesOpen && (
        <div style={{width: '450px' }} className="messages absolute top-16 right-80 bg-white border border-gray-300 rounded-md p-4 max-h-72 overflow-y-auto">
          <div className="message mb-4">
            <h1 className="text-xl font-bold flex items-center mb-4"><CiBellOn className="mr-2" /> Notifications</h1>
          </div>
          <div className="message py-2 px-4 rounded-lg mb-2" style={{ backgroundColor: '#117575', color: '#9ccaca' }}>
            Today 14:50 PM<br /> Standard eSIM Bundles Pricing Updated! Check out the latest rates now.
          </div>
          <div className="message py-2 px-4 rounded-lg mb-2" style={{ backgroundColor: '#e9f8f9' }}>
            Today 14:52 PM <br /> Standard eSIM Bundles Pricing Updated! Check out the latest rates now.
          </div>
          <div className="message py-2 px-4 rounded-lg mb-2" style={{ backgroundColor: '#e9f8f9' }}>
            Today 14:55 PM <br /> Standard eSIM Bundles Pricing Updated! Check out the latest rates now.
          </div>
          <div className="message py-2 px-4 rounded-lg mb-2" style={{ backgroundColor: '#e9f8f9' }}>
            Today 14:55 PM <br /> Standard eSIM Bundles Pricing Updated! Check out the latest rates now.
          </div>
        </div>
      )}
 <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Balance Added Modal"
                // Add styles for the modal
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '0px',
                        borderRadius: '8px',
                        height:'95%'
                    },
                }}
            >
                {/* Render the Balanceadded component inside the modal */}
                <Step1 />
            </Modal>
  
    </nav>
    
  );
};

export default Navbar;
