import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa';
import QRCode from 'qrcode.react'; // Import QR code library
import { saveAs } from 'file-saver';
import { ImCross } from "react-icons/im";
import { GoDownload } from "react-icons/go";

const SimManagement = () => {
  const [purchasePlans, setPurchasePlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [partnerSale, setPartnerSale] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchPurchaseData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const partnerCode = sessionStorage.getItem('partnerCode');

        if (!token || !partnerCode) {
          throw new Error('Token or partner code not found in session storage');
        }

        // Fetch partner purchase plans
        const purchasePlanResponse = await fetch(`${apiUrl}ESIM/partner_purchaseplan?partnercode=${partnerCode}&month=${selectedMonth}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!purchasePlanResponse.ok) {
          throw new Error('Failed to fetch purchase plans');
        }

        const purchasePlanData = await purchasePlanResponse.json();
        if (purchasePlanData.isSuccess) {
          setPurchasePlans(purchasePlanData.purchseplanlist);
        } else {
          setError('Failed to fetch purchase plans');
        }

        // Fetch partner sale data
        const saleReportResponse = await fetch(`${apiUrl}ESIM/Get_purchasereport_sourcewise?flag=3&partnercode=${partnerCode}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!saleReportResponse.ok) {
          throw new Error('Failed to fetch partner sale report');
        }

        const saleReportData = await saleReportResponse.json();
        if (saleReportData.isSuccess) {
          setPartnerSale(saleReportData.partnersale);
        } else {
          setError('Failed to fetch partner sale report');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchaseData();
  }, [selectedMonth]); // Re-fetch data when selectedMonth changes

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setQrModalOpen(true);
  };

  const closeModal = () => {
    setQrModalOpen(false);
    setSelectedPlan(null);
  };

  const handleDownload = (lpacode) => {
    // Simulate downloading logic here
    // For demonstration purposes, download a dummy QR code image
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${lpacode}&size=200x200`;
    saveAs(qrCodeUrl, `QR_${lpacode}.png`);
  };

  // Show only the first 10 items from purchasePlans
  const plansToShow = purchasePlans.slice(0, 100);

  if (loading) {
    return <div className="flex items-center justify-center bg-[#ffffff] h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <div className="mt-4 bg-white shadow p-4 flex justify-between items-center rounded-md">
        <h2 className="text-5xl font-bold mr-4"> Purchased Packages </h2>
        <div className="relative">
          <select
            className="bg-[#1ebab1] text-white p-2 rounded-md"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
        </div>
      </div>
      <table className="w-full bg-white rounded-md h-96">
        <thead>
          <tr>
            <th className="px-6 py-3 text-center">Order ID</th>
            <th className="px-6 py-3 text-center">Purchase Date</th>
            <th className="px-6 py-3 text-center">Plan Name</th>
            <th className="px-6 py-3 text-center">Purchase Amount</th>
            <th className="px-6 py-3 text-center">Sale Type</th>
            <th className="px-6 py-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {plansToShow.map((plan) => (
            <tr key={plan.orderid}>
              <td className="px-6 py-4 text-center">{plan.orderid}</td>
              <td className="px-6 py-4 text-center">{plan.purchasedate}</td>
              <td className="px-6 py-4 text-center">{plan.planname}</td>
              <td className="px-6 py-4 text-center">${plan.purchaseamount}</td>
              <td className="px-6 py-4 text-center">{partnerSale.length > 0 ? partnerSale[0].saletype : 'N/A'}</td>

              <td className="px-6 py-4 flex  text-center">
                <button
                  className="text-black font-bold py-2 px-4 rounded   space-x-2"
                  onClick={() => handleDownload(plan.lpacode)}
                >
                  <GoDownload />
                </button>
                <button
                  className="text-black font-bold py-2 px-4 rounded  space-x-2"
                  onClick={() => openModal(plan)}
                >
                  <FaEye />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {qrModalOpen && selectedPlan && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md flex flex-row relative">
            {/* Left column */}
            <div className="flex flex-col items-start w-96">
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">Order ID:</span>{' '}
                <span className="ml-4">{selectedPlan?.orderid || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">APN:</span>{' '}
                <span className="ml-4">{selectedPlan?.apn || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">ICCID:</span>{' '}
                <span className="ml-4">{selectedPlan?.iccid || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SIMPIN:</span>{' '}
                <span className="ml-4">{selectedPlan?.simpin || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SM-DP+Address:</span>{' '}
                <span className="ml-4">{selectedPlan?.address || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SM-DP+Address & Activation code:</span>{' '}
                <span className="ml-4">{selectedPlan?.activationCode || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">Authentication code:</span>{' '}
                <span className="ml-4">{selectedPlan?.authenticationCode || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-[#c5f1f1] rounded-2xl">
                <span className="font-bold">How to Install ?</span>
                <a href="https://www.airhubapp.com/info/Installation" target="_blank" rel="noopener noreferrer" className="ml-4 border-2 rounded-2xl hover:bg-blue-400 p-2 border-black ">Step by Step Guide</a>
              </div>
            </div>

            {/* Right column */}
            <div className="flex flex-col items-center justify-center ml-8">
              <QRCode value={selectedPlan.lpacode} className='border-4 p-2 border-[#47a192]' size={200} />
              <button
                className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mt-4"
                onClick={() => handleDownload(selectedPlan.lpacode)}
              >
                <GoDownload size={30} /> Download
              </button>
            </div>

            {/* Close button */}
            <ImCross size={30} onClick={closeModal} className="cursor-pointer absolute top-2 right-2" />
          </div>
        </div>
      )}

      {/* <div className="mt-4 flex justify-center">
        <div className="flex space-x-2">
          <button className="bg-blue-500 text-white px-2 py-1 rounded">1</button>
          {[...Array(8)].map((_, index) => (
            <button key={index} className="bg-white px-2 py-1 rounded">{index + 2}</button>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default SimManagement;
