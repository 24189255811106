import React, { useState, useEffect } from 'react';

function StepHeader() {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedName = localStorage.getItem('Name');
    const storedUsername = localStorage.getItem('username');
    
    if (storedName) {
      setName(storedName);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div className="flex flex-row items-center justify-start gap-[20px]">
      <div className="relative" style={{ fontSize: "3rem" }}>👋</div>
      <div className="flex flex-col items-start justify-center text-[32px]">
        <div className="relative">
          <span className="font-semibold">Hey {name},</span>
        </div>
        {/* <div className="relative text-5xl">
          Learn the basics with this tutorial.
        </div> */}
      </div>
    </div>
  );
}

export default StepHeader;
