import React from 'react';
import Apiboxd from '../assets/images/apibox.svg';
import { MdOutlineFileDownload } from "react-icons/md";

function Apibox() {
  const handleDownload = () => {
    window.open('https://drive.google.com/file/d/1dDvXmaoU8Ap04-oVwdQzqKZumJ39Z9R-/view?usp=sharing', '_blank');
  };

  return (
    <>
      <div style={{ backgroundColor: '#ffffff' }} className="main-content flex-1 bg-teal-100 overflow-auto rounded-2xl h-fit-content p-8 ">
      <h1 className='text-5xl  font-bold text-[#197979]'>API BOX</h1>

      <div className='flex flex-col lg:flex-row bg-white rounded-3xl'>
        <div className='flex-1 flex flex-col justify-center '>
          <h1 className='text-center text-5xl font-bold mb-8'>Manage API Access & Documentation</h1>
          <div className='flex justify-center'>
            <button 
              className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mb-8 mr-4"
              onClick={handleDownload}
            >
              Download API <MdOutlineFileDownload size={30} />
            </button>
            {/* <button 
              className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mb-8"
              onClick={handleDownload}
            >
              API Document <MdOutlineFileDownload size={30} /> 
            </button> */}
          </div>
        </div>
        <div className='flex-1 p-2'>
          <img src={Apiboxd} alt='api' className='rounded-2xl' />
        </div>
      </div>
    </div>
    </>

  );
}

export default Apibox;
