import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa';
import QRCode from 'qrcode.react'; // Import QR code library
import { saveAs } from 'file-saver';
import { ImCross } from "react-icons/im";
import { GoDownload } from "react-icons/go";

const PurchaseReport = () => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [filter, setFilter] = useState(''); // State to manage dropdown filter
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const token = localStorage.getItem('token');
        const partnerCode = localStorage.getItem('partnerCode');
        const response = await fetch(    `${apiUrl }ESIM/Get_purchasereport_sourcewise?flag=3&partnercode=${partnerCode}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.isSuccess) {
          setReportData(data.partnersale);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, []);

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setQrModalOpen(true);
  };

  const closeModal = () => {
    setQrModalOpen(false);
    setSelectedPlan(null);
  };

  const handleDownload = (lpacode) => {
    // Simulate downloading logic here
    // For demonstration purposes, download a dummy QR code image
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${lpacode}&size=200x200`;
    saveAs(qrCodeUrl, `QR_${lpacode}.png`);
  };

  // Filter report data based on selected filter
  const filteredReportData = filter ? reportData.filter(sale => sale.saletype === filter) : reportData;

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container mx-auto p-4">
            <div className="flex justify-between items-center p-2 bg-white">
  <h2 className="text-5xl font-bold ">Purchase History</h2>
  <div className="flex items-center">
    <label htmlFor="filter" className="mr-2 text-5xl font-bold">Sale Type:</label>
    <select
      id="filter"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      className="px-4 py-2 border border-gray-700 rounded-md"
    >
      <option value="">Type</option>
      <option value="API">API</option>
      <option value="Portal">Portal</option>
    </select>
  </div>
</div>

      <table className="w-full bg-white rounded-md">
        <thead>
          <tr>
            <th className="px-6 py-3 text-center">Order ID</th>
            <th className="px-6 py-3 text-center">Purchase Date</th>
            <th className="px-6 py-3 text-center">Plan Name</th>
            <th className="px-6 py-3 text-center">Purchase Amount</th>
            <th className="px-6 py-3 text-center">Sale Type</th>
            <th className="px-6 py-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {filteredReportData.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center py-4">
                <p className="text-lg font-bold">No Active Packages</p>
              </td>
            </tr>
          ) : (
            filteredReportData.map((sale, index) => (
              <tr key={index}>
                <td className="px-6 py-4 text-center">{sale.order_id}</td>
                <td className="px-6 py-4 text-center">{sale.purchasedate}</td>
                <td className="px-6 py-4 text-center">{sale.planname}</td>
                <td className="py-2 px-4 border-b">${parseFloat(sale.purchaseamount).toFixed(2)}</td>
                <td className="px-6 py-4 text-center">{sale.saletype}</td>
                <td className="px-6 py-4 flex text-center">
                  <button
                    className="text-black font-bold py-2 px-4 rounded space-x-2"
                    onClick={() => handleDownload(sale.activationCode)}
                  >
                    <GoDownload />
                  </button>
                  <button
                    className="text-black font-bold py-2 px-4 rounded space-x-2"
                    onClick={() => openModal(sale)}
                  >
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {qrModalOpen && selectedPlan && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md flex flex-row relative">
            {/* Left column */}
            <div className="flex flex-col items-start w-96">
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">Order ID:</span>{' '}
                <span className="ml-4">{selectedPlan?.orderid || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">APN:</span>{' '}
                <span className="ml-4">{selectedPlan?.apn || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">ICCID:</span>{' '}
                <span className="ml-4">{selectedPlan?.iccid || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SIMPIN:</span>{' '}
                <span className="ml-4">{selectedPlan?.simpin || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SM-DP+Address:</span>{' '}
                <span className="ml-4">{selectedPlan?.address || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold"> Activation code:</span>{' '}
                <span className="ml-4">{selectedPlan?.activationCod || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-[#c5f1f1] rounded-2xl">
                <span className="font-bold">How to Install ?</span>
                <a href="https://www.airhubapp.com/info/Installation" target="_blank" rel="noopener noreferrer" className="ml-4 border-2 rounded-2xl hover:bg-blue-400 p-2 border-black">Step by Step Guide</a>
              </div>
            </div>

            {/* Right column */}
            <div className="flex flex-col items-center justify-center ml-8">
              <QRCode value={selectedPlan.activationCode} className='border-4 p-2 border-[#47a192]' size={200} />
              <button
                className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mt-4"
                onClick={() => handleDownload(selectedPlan.activationCode)}
              >
                <GoDownload size={30} /> Download
              </button>
            </div>

            {/* Close button */}
            <ImCross size={30} onClick={closeModal} className="cursor-pointer absolute top-2 right-2" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseReport;
