import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Coin from '../assets/images/coin.svg';
import { useNavigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { GoDownload } from "react-icons/go";
import QRCode from 'qrcode.react'; // Import QR code library
import { saveAs } from 'file-saver';

const PlanCountRegionwise = () => {
  const [planData, setPlanData] = useState([]);
  const [purchasePlanData, setPurchasePlanData] = useState([]);
  const [walletData, setWalletData] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedLpaCode, setSelectedLpaCode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const itemsPerPage =5;

  const navigate = useNavigate();

  const handletopups = () => {
    navigate('/AddmoreBalance');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const partnerCode = localStorage.getItem('partnerCode');

    if (token) {
      axios.get(`${apiUrl }ESIM/Get_plancount_regionwise?flag=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.isSuccess) {
          setPlanData(response.data.planlists);
        }
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });

      axios.get(`${apiUrl }ESIM/partner_purchaseplan?partnercode=${partnerCode}&month=${selectedMonth}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.isSuccess) {
          setPurchasePlanData(response.data.purchseplanlist);
        }
      })
      .catch(error => {
        console.error('Error fetching the purchase plan data', error);
      });
    } else {
      console.error('No token found in session storage');
    }
  }, [selectedMonth]);

  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem('token');
      const storedPartnerCode = localStorage.getItem('partnerCode');
      const storedRole = localStorage.getItem('role');

      if (storedSessionToken && storedPartnerCode && storedRole) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
        setIsAdmin(storedRole === 'admin');
      } else {
        console.error('Session data not found');
      }
    };

    fetchSessionData();
  }, []);

  useEffect(() => { 
    const fetchWalletData = async () => {
      const sessionToken  = localStorage.getItem('token');
      const  partnerCode= localStorage.getItem('partnerCode');
      try {
        if (!sessionToken || !partnerCode) {
          console.error('Session token or partner code missing');
          return;
        }

        const response = await axios.get(
          `${apiUrl }ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWalletData(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error('Error fetching wallet data:', error);
      }
    };

    fetchWalletData();
  }, [sessionToken, partnerCode]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDownload = (lpacode) => {
    // Simulate downloading logic here
    // For demonstration purposes, download a dummy QR code image
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${lpacode}&size=200x200`;
    saveAs(qrCodeUrl, `QR_${lpacode}.png`);
  };

  const openModal = (lpacode) => {
    setSelectedLpaCode(lpacode);
    setQrModalOpen(true);
  };

  const closeModal = () => {
    setQrModalOpen(false);
    setSelectedLpaCode(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedPlans = purchasePlanData.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(purchasePlanData.length / itemsPerPage);

  return (
    <div className="container mx-auto ">
      <div className="flex flex-wrap">
    
      <div className="w-full lg:w-2/3 p-2 flex">
  <div className="bg-white shadow rounded-lg p-4 flex-grow">
    <h2 className="text-5xl font-bold mb-4">Available Regional Plans</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {planData.map((plan, index) => (
        <div 
          key={index} 
          className="flex justify-between items-center h-14 w-46 bg-[#dbedec] rounded-lg">
          <div className="flex-1 text-center text-[#3a9a8a]">
            <span className="font-sm text-lg">
              {plan.regionname ? plan.regionname : 'Country Plans'}
            </span>
          </div>
          <div className="m-2 p-2 w-16 text-center text-[#117575] font-bold bg-[#ffffff] rounded-lg">
            {plan.plancount}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>



<div className="w-full lg:w-1/3 p-2 flex">
          <div className="bg-white shadow rounded-lg p-4 flex-grow">
            <h1 className="text-5xl font-bold mb-4 flex justify-between items-center">
              Wallet Balance <img src={Coin} alt="coin" />
            </h1>
            
            <div style={{ fontSize: '4.0rem' }} className="font-bold text-[#117575] text-center my-4 mb-8 mt-8">
              {walletData ? (
                <p className="text-2xl font-bold">${`${walletData.balance}`}</p>
              ) : (
                <p className="text-lg text-gray-600">Loading...</p>
              )}
            </div>
            <button className="  text-[#595959] w-full flex justify-center items-center border border-black px-4 py-2 rounded mb-8" onClick={handletopups}>
              Add more balance <MdOutlineKeyboardArrowRight size={40} />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4 bg-white shadow rounded-t-lg p-4 flex justify-between items-center">
        <h2 className="text-5xl font-bold ">Purchased Plans</h2>
        <div className="relative">
          <select
            className="bg-[#1ebab1] text-white p-2 rounded"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
        </div>
      </div>

      {selectedPlans.length === 0 ? (
                <p className="text-lg bg-[#ffffff] font-extrabold text-center h-56 text-gray-600">No Active Packages</p>
              ) : (
  <table className="bg-white p-4 w-full rounded-md">
    <thead>
      <tr>
        {/* <th className="px-6 py-3 text-center">QR</th> */}
        <th className="px-6 py-3 text-center">Order ID</th>
        <th className="px-6 py-3 text-center">Plan Name</th>
        <th className="px-6 py-3 text-center">Purchase Amount</th>
        <th className="px-6 py-3 text-center">Purchase Date</th>
        <th className="px-6 py-3 text-center">View</th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200">
      {selectedPlans.map((plan) => (
        <tr key={plan.orderid}>
          {/* <td className="py-4 px-6 flex items-center">
            <BiQrScan className="mr-2" />
          </td> */}
          <td className="px-6 py-4 text-center">{plan.orderid}</td>
          <td className="px-6 py-4 text-center">{plan.planname}</td>
          <td className="px-6 py-4 text-center">${plan.purchaseamount}</td>
          <td className="px-6 py-4 text-center">{plan.purchasedate}</td>
          <td className="px-6 py-4 flex justify-center">
            <button
              className="text-black font-bold py-2 px-4 rounded flex space-x-2"
              onClick={() => handleDownload(plan.lpacode)}
            >
              <GoDownload />
            </button>
            <button
              className="text-black font-bold py-2 px-4 rounded flex space-x-2"
              onClick={() => openModal(plan.lpacode)}
            >
              <FaEye />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}


      {/* Modal for QR Code */}
      {qrModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md">
            <QRCode value={selectedLpaCode} size={200} />
            <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <div className="bg-[#ffffff] flex justify-center">
        <div className="flex space-x-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-3 py-1 rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanCountRegionwise;
