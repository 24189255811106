import React,{useState} from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import CreateAccount from "./CreateAccount";
import StepHeader from "./StepHeader";

const Step5 = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
    const handleNavigate = () => {
        setModalIsOpen(true); // Open the modal when "Top-Ups" button is clicked
    };
   
  const handleNavigateToDetails = () => {
    navigate('/Home');
  };
  const handleCancel = () => {
    navigate('/Home');
  };
  return (
<>
    <div className=" flex justify-center items-center h-screen">
    <div style={{ backgroundColor: "#e9f8f9" }} className="container rounded-2xl h-[96%] p-2 m-10  relative ">
    <StepHeader/>

      <div className="w-full rounded-11xl bg-powderblue overflow-hidden flex flex-row items-start justify-start gap-[90px] text-center text-5xl text-brand-white">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">1</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
          <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">2</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">3</div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl bg-brand-green-deselected h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">4</div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="w-10 rounded-11xl [background:linear-gradient(-45deg,_#1298aa,_#29d7b7)] h-10 overflow-hidden shrink-0 flex flex-col items-center justify-center py-[5px] px-0 box-border">
              <div className="relative font-medium">5</div>
            </div>
          </div>
        </div>
      <h1 className="text-2xl font-semibold mt-4">
        Step 4: Scale your eSIM Business
      </h1>
      <br></br>
      <div style={{backgroundColor:'#ffffff' }} className="box1 w-full   shadow-lg rounded-xl mb-4 p-8">
  <p className="text-xl "> 
 
"Empower your connectivity with our scalable eSIM solution - where flexibility meets seamless integration, revolutionizing how you stay connected."
  </p>
</div>

<div style={{backgroundColor:'#ffffff' }} className="box1 shadow-lg rounded-xl mb-4 p-8">
  <p className="text-xl "> 
  "Experience the world of connectivity redefined with our cutting-edge eSIM technology – where boundaries dissolve and possibilities expand infinitely." <br></br>
 <p></p>
 <br></br>
 <div className="flex justify-center items-center">

    <button  style={{backgroundColor: '#117575'}} onClick ={handleNavigate} className="text-white px-4 py-2 rounded-full font-semibold">
       Contact Account Mangaer
      </button>
      </div>
  </p>
</div>


      <div className="absolute bottom-0 left-0 w-full bg-daefef p-8 flex justify-between">
        <button
          className="bg-gray-500 text-white px-8 py-2 rounded-full font-semibold"
          onClick={handleCancel}        >
          Skip
        </button>
        <button className="bg-teal-500 text-white px-8 py-2 rounded-full font-semibold"            onClick={handleNavigateToDetails}>
          Next
        </button>
      </div>
    </div>
    </div>
     {/* Modal for displaying Balanceadded component */}
     <Modal
     isOpen={modalIsOpen}
     onRequestClose={() => setModalIsOpen(false)}
     contentLabel="Balance Added Modal"
     // Add styles for the modal
     style={{
         overlay: {
             backgroundColor: 'rgba(0, 0, 0, 0.5)',
         },
         content: {
             width: 'fit-content',
             margin: 'auto',
             padding: '0px',
             borderRadius: '8px',
             height:'fit-content'
         },
     }}
 >
     {/* Render the Balanceadded component inside the modal */}
     <CreateAccount />
 </Modal>
 </>
  );
};

export default Step5;
