import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { GoDownload } from "react-icons/go";


const PurchaseReportTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(1, "month").toDate()
  );
  const [toDate, setToDate] = useState(dayjs().toDate());
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  useEffect(() => {
    filterData();
  }, [searchTerm, data]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const from = dayjs(fromDate).format("YYYY-MM-DD");
      const to = dayjs(toDate).format("YYYY-MM-DD");

      const response = await axios.get(
        "https://scjapi.clay.in/api/ESIMGOAdmin/Get_purchasereport_sourcewise",
        {
          params: {
            flag: 1,
            fromdate: from,
            todate: to,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.isSuccess) {
        setData(response.data.partnersale);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const newFilteredData = data.filter(
      (item) =>
        item.partnername.toLowerCase().includes(lowercasedSearchTerm) ||
        item.companyname.toLowerCase().includes(lowercasedSearchTerm) ||
        item.planname.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredData(newFilteredData);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleSearch = () => {
    filterData();
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(filterData.length / recordsPerPage))
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentItems = filteredData.slice(startIndex, startIndex + recordsPerPage);
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  
  const Pagination = ({ totalPages, currentPage, onPageClick }) => {
    const maxVisiblePages = 5; // Maximum number of visible page numbers
    let pages = [];

    if (totalPages <= maxVisiblePages) {
      pages = Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const leftEllipsis = currentPage > 4;
      const rightEllipsis = currentPage < totalPages - 3;

      if (leftEllipsis) {
        pages.push(1);
        pages.push(',');
      }

      for (
        let i = Math.max(2, currentPage - 2);
        i <= Math.min(totalPages - 1, currentPage + 2);
        i++
      ) {
        pages.push(i);
      }

      if (rightEllipsis) {
        pages.push(',');
        pages.push(totalPages);
      }
    }
    return (
      <div className="flex justify-between mt-4">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <div className="flex">
          {pages.map((page, index) => (
           <button
           key={index}
           className={`pagination-item ${
             currentPage === page ? "active" : ""
           }`}
           onClick={() => page !== "..." && onPageClick(page)}
           style={{
             fontWeight: currentPage === page ? "bold" : "normal",
             backgroundColor: currentPage === page ? "#117575" : "transparent",
             color: currentPage === page ? "white" : "black",
             padding:'10px',
             borderRadius:'0.9rem'
           }}
         >
           {page}
         </button>

          ))}
        </div>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </button>
      </div>
    );
  };

  const convertToCSV = (array) => {
    const headers = [
      "Partner Name",
      "Company Name",
      "Purchase Date",
      "Purchase Amount",
      "Plan Name",
      "Sale Type",
    ];
    const rows = array.map((item) => [
      item.partnername,
      item.companyname,
      item.purchasedate,
      item.purchaseamount,
      item.planname,
      item.saletype,
    ]);

    let csvContent = "data:text/csv;charset=utf-8," 
      + headers.join(",") 
      + "\n" 
      + rows.map((row) => row.join(",")).join("\n");

    return encodeURI(csvContent);
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(data);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "purchase_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Calculate the indices for slicing the data
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  return (
    <div className="mt-4 bg-white shadow p-4 justify-between items-center rounded-3xl ">
    <div className="flex justify-between items-center mb-4">
  {/* Left side - Title */}
  <h1 className="text-5xl font-bold">Purchase Details</h1>

  {/* Right side - Button and Search */}
  <div className="flex items-center space-x-4">
    {/* Button to download CSV */}
    <button
      onClick={downloadCSV}
      className="px-4 py-2  text-[#105255] rounded-md font-bold flex items-center space-x-2"
    >
      <GoDownload size={28} />
      <span>Export</span>
    </button>

    {/* Search Input */}
     <div className="relative">
  <input
    type="text"
    placeholder="Search by partner code, name, or email"
    className="border-2 bg-[#7bc2c5] rounded-full p-2 pl-10 w-96 text-[#105255] placeholder-[#105255]" 
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
    <IoMdSearch size={20} className="text-[#105255]" />
  </div>
</div>

  </div>
</div>

      <div className="mt-4 p-4 flex justify-between items-center ">
       
        {error && <div className="text-red-500">{error}</div>}
        
       
        
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-center border-b">Partner Name</th>
              <th className="py-2 px-4 text-center border-b">Company Name</th>
              <th className="py-2 px-4 text-center border-b">Purchase Date</th>
              <th className="py-2 px-4 text-center border-b">Purchase Amount </th>
              <th className="py-2 px-4 text-center border-b">Plan Name</th>
              <th className="py-2 px-4 text-center border-b">Sale Type</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 text-center border-b">{item.partnername}</td>
                <td className="py-2 px-4 text-center border-b">{item.companyname}</td>
                <td className="py-2 px-4 text-center border-b">{item.purchasedate}</td>
                <td className="py-2 px-4 text-center border-b">${parseFloat(item.purchaseamount).toFixed(2)}</td>
                <td className="py-2 px-4 text-center border-b">{item.planname}</td>
                <td className="py-2 px-4 text-center border-b">{item.saletype}</td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </div>

      <div className=" flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300"
        >
          <FaChevronLeft />
        </button>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageClick={handlePageClick}

  />
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * recordsPerPage >= filteredData.length}
          className="px-4 py-2 bg-gray-300"
        >

          <FaChevronRight />{" "}
        </button>
      </div>
    </div>
  );
};

export default PurchaseReportTable;
